function appendChildContent(ele, content) {
    const element = document.getElementById(ele);
    //console.log(ele);
    if (element) {
        element.innerHTML = content;
        //element.appendChild(element);
        return true;
    } else {
        return false;
    }
}

//function appendBodyContent(ele, content) {
//    const element = document.getElementById(ele);
//    if (element != null || element != undefined || element != "") {
//        element.body.appendChild(content);
//    }
//}

//function appendChildContent(ele , content) {
//    const element = document.getElementById(ele);

//    if (element != null || element != undefined || element != "") {
//        element.body.appendChild(content);
//    }
//}