function encodeString(strng) {
    const uri = strng;
    const encoded = encodeURI(uri);

    return encoded;
}

function encodeComponentString(strng) {
    const uri = strng;
    const encoded = encodeURIComponent(uri);

    return encoded;
}


function decodeString(strng) {
    const uri = strng;
    const decoded = decodeURI(uri);

    return decoded;

}


function encodeBase64(strng) {
    const encoded = btoa(strng);

    return encoded;
}

function decodeBase64(strng) {

    const decoded = atob(strng);

    return decoded;

}