function RemoveAllCaches() {
    caches.keys().then(function (names) {
        for (let name of names) { 
            caches.delete(name);
        }
        window.location.reload(true);
    });
}

//windows.location.reload(true)


//caches.keys().then(keys => {
//    keys.forEach(key => caches.delete(key))
//})
