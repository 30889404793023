function GetDimensionOfElement(ele) {
    const element = document.getElementById(ele);
    const rect = element.getBoundingClientRect();
    //console.log("Height: " + rect.height + "px");
    //console.log("width: " + rect.width + "px");
    return rect;
}

//function GetWidthOfElement(ele) {
//    const element = document.getElementById(ele);
//    const rect = element.getBoundingClientRect();
//    //console.log("Width: " + rect.width + "px");
//    return rect.width;
//}