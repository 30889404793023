function OTPAutoFillInit(input) {
    if (!window.OTPCredential) {
        console.log('Feature Not Available');
    }
    else {
        //console.log('OTP Feature Available');
        window.addEventListener('DOMContentLoaded', e => {
            //const input = document.querySelector('input[autocomplete="one-time-code"]');
            if (!input) return;
            var ac = new AbortController();
            var form = input.closest('form');
            if (form) {
                form.addEventListener('submit', e => {
                    ac.abort();
                });
            }

            navigator.credentials.get({
                otp: { transport: ['sms'] },
                signal: ac.signal
            }).then(otp => {
                input.value = otp.code;
                if (form) form.submit();
            }).catch(err => {
                //console.log(err);
            });
        });
    }
    //if ('OTPCredential' in window) {

    //}

}




//window.webOtpHandler = { otpReceived: null, startOtpVerification: function () { const otpRequest = new OTPCredentialRequestOptions({ transport: ["sms"] }); // Request the OTP code via the Web OTP API window.navigator.credentials.get(otpRequest) .then(otp => { if (otp) { window.webOtpHandler.otpReceived = otp.code; // You can also display the received OTP code in the UI if needed. console.log("Received OTP code:", otp.code); } }) .catch(error => { console.error("Error retrieving OTP:", error); }); } };