var x = false;
function TimeOut(timer) {
    return new Promise(function (resolve) {
        setTimeout(function () {
            x = true;
            resolve(x);
        }, timer);
    });
}

TimeOut(timer = 0).then(function (e) {
    //console.log(e);
    x = false;
    return e;
});



