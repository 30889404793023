

function removeInvalidChar() {
    setTimeout(() => {
        let input = this.control.control.value;

        if (input) {
            input.split('').forEach((char) => {
                if (!this.pattern.test(char)) {
                    input = input.replace(char, '');
                }
            });
            this.control.control.setValue(input);
        }
    });
}


//function preventNonNumericalInput(element) {

//    // 'left arrow', 'up arrow', 'right arrow', 'down arrow',
//    var arrowsKeyCodes = [37, 38, 39, 40];
//    //var NUMBER_ALLOWED_CHARS_REGEXP = /^[0-9]+$/;


//    //console.log(e);
//    //var element = document.getElementById(e);

//    //var charCode = element.keyCode ;
//    //var charStr = String.fromCharCode(charCode);
//    //if (!charStr.match(/^[0-9]+$/))
//    //    element.preventDefault();

//    //    if ((e.keyCode < 48 && !arrowsKeyCodes.includes(e.keyCode) || e.keyCode > 57 &&
//    //        !numPadNumberKeyCodes.includes(e.keyCode)) &&
//    //        !(e.keyCode === 8 || e.keyCode === 9))
//    //        e.preventDefault()
//    //}


//    //element.addEventListener("input", event => {
//    //    if (!NUMBER_ALLOWED_CHARS_REGEXP.test(event.key)) {
//    //        event.preventDefault();
//    //    }
//    //});

//    element.addEventListener("keydown", (e) => {
//        var keyCode = (e.keyCode ? e.keyCode : e.which);

//        console.log(`Key "${e.keyCode}" released [event: keyup]`);
//        if (!(keyCode > 95 && keyCode < 106 || keyCode > 47 && keyCode < 58 || keyCode == 8 || keyCode == 9 || keyCode == 13 || e.ctrlKey || arrowsKeyCodes.includes(keyCode))) {
//            e.preventDefault();
//        }
//    });

//    element.addEventListener("keyup", (e) => {
//        var keyCode = (e.keyCode ? e.keyCode : e.which);

//        console.log(`Key "${e.keyCode}" released [event: keyup]`);
//        if (!(keyCode > 95 && keyCode < 106 || keyCode > 47 && keyCode < 58 || keyCode == 8 || keyCode == 9 || keyCode == 13 || e.ctrlKey || arrowsKeyCodes.includes(keyCode))) {
//            e.target.value = e.target.value.replace(/^[a-z\s'&]*$/i, '');
//            e.preventDefault();
//        }
//    });

//    //element.addEventListener("keyup", (e) => {
//    //    var keyCode = (e.keyCode ? e.keyCode : e.which);

//    //    console.log(`Key "${e.keyCode}" released [event: keyup]`);
//    //    if (!(keyCode > 95 && keyCode < 106 || keyCode > 47 && keyCode < 58 || keyCode == 8 || keyCode == 9 || keyCode == 13 || e.ctrlKey || arrowsKeyCodes.includes(keyCode))) {
//    //        e.target.value = e.target.value.replace(/^[a-z\s'&]*$/i, '');

//    //    }
//    //});

//    //console.log(e);

//}
//function preventNonNumericalInput(e) {
//    //console.log(e);
//    var element = document.getElementById(e);
//    e = element || window.event;
//    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
//    var charStr = String.fromCharCode(charCode);
//    if (!charStr.match(/^[0-9]+$/))
//        e.preventDefault();
//    //console.log(e);
//}

//function preventNonNumericalInput(element) {
//    //console.log(element);
//    var arrowsKeyCodes = [37, 38, 39, 40];
//    var keyCode;
//    //var eventList = ["keyup", "keydown", "input", "change", "paste", "propertychange"];
//    var eventList = ["keyup", "keydown", "input"];
//    eventList.forEach(event => element.addEventListener(event, (e) => {
//        //console.log(event);

//        //var keyCode = e.key ? e.key : (e.data ? e.data : (e.keyCode ? e.keyCode : e.which));
//        //console.log(keyCode);

//        if (e.key != "undefined" || e.key != null) {
//             keyCode = e.key;
//            console.log(keyCode);
//            if ((event == "keyup") || (event == "keydown")) {
//                if (event == "keyup") {
//                    //console.log(`Key "${e.key}" released [event: keyup]`);
//                    return;

//                } else if (event == "keydown") {
//                    //console.log(`KeyCode "${e.keyCode}" released [event: keydown]`);
//                }

//                //var regex = /^[0-9]+$/;
//                if (!(keyCode >= "0" && keyCode <= "9" || keyCode == "Backspace" || keyCode == "Enter" || keyCode == "Tab" || keyCode == "Delete" || keyCode == "Control")) {
//                    e.preventDefault();
//                }
//            }

//        }
//        //else if (e.data != "undefined" || e.data != null ) {
//        //    if (event == "input") {
//        //        keyCode = e.data;
//        //        console.log(keyCode);
//        //        var regex = /\d/;
//        //        if (!regex.test(keyCode)) {
//        //            event.preventDefault();
//        //        }
//        //        //console.log(`Data "${e.data}" released [event: input]`);
//        //    }
//        //} else if (e.keyCode) {

//        //    if (!(keyCode > 95 && keyCode < 106 || keyCode > 47 && keyCode < 58 || keyCode == 8 || keyCode == 9 || keyCode == 13 || e.ctrlKey || arrowsKeyCodes.includes(keyCode))) {
//        //        e.preventDefault();
//        //    }
//        //    //console.log(`Data "${e.keyCode}" released [event: input]`);

//        //} else if (e.which) {
//        //                //console.log(`Data "${e.which}" released [event: input]`);

//        //} else {
//        //    e.target.value = e.target.value.replace(/^[a-z\s'&]*$/i, '');
//        //    //console.log(`Which "${e.which}" released [event: Which]`);
//        //}










//    }))


//    //function addMultipleEventListener(element, events, handler) {
//    //    events.forEach(e => element.addEventListener(e, handler))
//    //}


//    //console.log(e);
//}






//function preventNonEnglishCharInput(e) {
//    //console.log(e);
//    var element = document.getElementById(e);
//    e = element || window.event;
//    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
//    var charStr = String.fromCharCode(charCode);
//    if (!charStr.match(/^[a-z\s'&]*$/i))
//        e.preventDefault();

//    //console.log(e);

//}


function alphaOnly(event) {
    event.target.value = event.target.value.replace(/[^a-z]/ig, '');
}



function JSpreventNonNumericalInput(e) {
    e = e || window.event;
    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[0-9]+$/))
        e.preventDefault();
}


function preventNonEnglishInput(e) {
    e = e || window.event;
    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[a-zA-Z$@$!%*?&#^-_. +]+$/))
        e.preventDefault();
}

function isNumberKey(e) {
    //console.log("now in is number key");
    if (e.charCode === 0 || /\d/.test(String.fromCharCode(e.charCode))) {
        return true;
    } else {
        e.preventDefault();
    }
}

//function preventNonEnglishCharInput(e) {
//    //console.log(e);
//    var element = document.getElementById(e);
//    var charCode = element.keyCode;
//    var charStr = String.fromCharCode(charCode);
//    if (!charStr.match(/^[a-z\s'&]*$/i))
//        element.preventDefault();

//    element.addEventListener("keyup", (e) => {
//        logMessage(`Key "${e.key}" released [event: keyup]`);
//        e.target.value = e.target.value.replace(/^[a-z\s'&]*$/i, '');

//    });
//    console.log(e);

//}

function preventNonEnglishCharInput(e) {
    //console.log(e);
    //console.log("preventNonEnglishCharInput");
    var element = document.getElementById(e);
    e = element || window.event;
    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[a-z\s'&]*$/i))
        e.preventDefault();

    //console.log(e);

}


function preventNonEnglishCharWithNumInput(e) {
    //console.log(e);
    var element = document.getElementById(e);
    e = element || window.event;
    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[A-Za-z0-9@._-]+$/))
        e.preventDefault();

    //console.log(e);

}

//function preventNonEnglishCharWithNumInput(e) {
//    //console.log(e);
//    var element = document.getElementById(e);
//    e = element || window.event;
//    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
//    var charStr = String.fromCharCode(charCode);
//    if (!charStr.match(/^[A-Za-z0-9@.-_]+$/))
//        e.preventDefault();

//    //console.log(e);

//}

function ReplaceString(x, e) {
    //console.log(e);
    var element = document.getElementById(e);
    const repStr = str.replace(/^[A-Za-z]+$/g, "");

    //console.log(e);

}

function RegexToStrings(charCode) {
    var charStr = String.fromCharCode(charCode);
    return charStr;
}

function InputTypeRegex(Type, e, keyCode, regex = true) {
    var arrowsKeyCodes = [37, 38, 39, 40];

    var specialKeyCodes = [".", "!", "~", "`", "#", "$", "%", "^", "&", "*", "(", ")", "=", "+", " ", "|", "/", "?", ">", "<", "'", "\\", ":", ";"];
    var GeneralKeyCodes = ["Backspace", "Enter", "Tab", "Delete", "Control"];
    var arrowKeyCodes = ["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown"];
    var EnterKeyCodes = "Enter";
    var BackspaceKeyCodes = "Backspace";
    var SpaceKeyCodes = "Space";
    //var numberKeyCodes = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    //const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    var charRegex = /^[A-Za-z]+$/;
    var numberRegex = /^[0-9]+$/;
    var emailRegex = /^[A-Za-z0-9@.-_]+$/;
    var alphaNumericRegex = /^[A-Za-z0-9]+$/;

    //console.log(RegexToStrings(keyCode));
    //console.log("In InputTypeRegex");
    switch (Type) {
        case "number":
            if (regex) {
                if (!(keyCode.match(numberRegex) || GeneralKeyCodes.includes(keyCode) || e.ctrlKey || arrowKeyCodes.includes(keyCode))) {
                    e.preventDefault();
                }
            } else {
                if (!(keyCode >= 96 && keyCode <= 105 || keyCode >= 48 && keyCode <= 57 || keyCode == 8 || keyCode == 9 || keyCode == 13 || e.ctrlKey || arrowsKeyCodes.includes(keyCode)) || e.shiftKey) {
                    e.preventDefault();
                }
            }
            break;
        case "email":
            if (regex) {
                if (!(keyCode.match(emailRegex) || GeneralKeyCodes.includes(keyCode) || e.ctrlKey || arrowKeyCodes.includes(keyCode))) {
                    e.preventDefault();
                }
            } else {
                e.target.value = e.target.value.replace(!emailRegex, '');
            }
            break;
        case "character":
            if (regex) {
                if (!(keyCode.match(charRegex) || GeneralKeyCodes.includes(keyCode) || e.ctrlKey || arrowKeyCodes.includes(keyCode))) {
                    e.preventDefault();
                }
            } else {
                if (keyCode > 95 && keyCode < 106 || keyCode >= 48 && keyCode <= 57) {
                    e.preventDefault();
                }
            }
            break;
        case "alphanumber":
            if (regex) {

                if (!(keyCode.match(alphaNumericRegex) || GeneralKeyCodes.includes(keyCode) || e.ctrlKey || arrowKeyCodes.includes(keyCode))) {
                    e.preventDefault();
                }
            } else {

                if (e.shiftKey) {
                    e.preventDefault();
                }
            }
            break;
        case "enter":
            if (regex) {
                if (!(keyCode.match(EnterKeyCodes) || GeneralKeyCodes.includes(keyCode) || e.ctrlKey || arrowKeyCodes.includes(keyCode))) {
                    e.preventDefault();
                }
            } else {
                if (keyCode != 13) {
                    e.preventDefault();
                }
            }
            break;
        case "backspace":
            if (regex) {
                if (!(keyCode.match(BackspaceKeyCodes) || GeneralKeyCodes.includes(keyCode) || e.ctrlKey || arrowKeyCodes.includes(keyCode))) {
                    e.preventDefault();
                }
            } else {
                if (keyCode != 8) {
                    e.preventDefault();
                }
            }
            break;
        case "space":
            if (regex) {
                if (!(keyCode.match(SpaceKeyCodes) || GeneralKeyCodes.includes(keyCode))) {
                    e.preventDefault();
                }
            } else {
                if (keyCode != 32) {
                    e.preventDefault();
                }
            }
            break;

        /*   default:*/
    }

}


function preventNonNumericalInput(e, type) {
    //console.log(e);
    var element = document.getElementById(e);
    //console.log(element);
    var arrowsKeyCodes = [37, 38, 39, 40];
    var keyCode;
    //var eventList = ["keyup", "keydown", "input", "change", "paste", "propertychange"];
    var eventList = ["keyup", "keydown", "input"];
    eventList.forEach(event => element.addEventListener(event, (e) => {
        console.log(event);

        //var keyCode = e.key ? e.key : (e.data ? e.data : (e.keyCode ? e.keyCode : e.which));



        if ((event == "keyup") || (event == "keydown")) {
            if (event == "keyup") {
                //console.log(`keyup Key "${e.key}" released [event: keyup]`);
                //console.log(`keyup KeyCode "${e.keyCode}" released [event: keyup]`);
                //console.log(`keyup Data "${e.which}" released [event: keyup]`);

            } else if (event == "keydown") {
                //console.log(`keydown Key "${e.key}" released [event: keydown]`);
                //console.log(`keydown KeyCode "${e.keyCode}" released [event: keydown]`);
                //console.log(`keydown Data "${e.which}" released [event: keydown]`);
            }
        }
        if (event == "input") {
            //console.log(`input Data "${e.data}" released [event: input]`);
            //console.log(`input which "${e.which}" released [event: input]`);
            //console.log(`input inputType "${e.inputType}" released [event: input]`);
        }


        if (e.key != "undefined" || e.key != null || e.key != 229 || e.key != "Unidentified") {
            keyCode = e.key;
            //console.log(keyCode);
            if ((event == "keyup") || (event == "keydown")) {
                //if (event == "keyup") {
                //    //console.log(`Key "${e.key}" released [event: keyup]`);

                //} else if (event == "keydown") {
                //    //console.log(`KeyCode "${e.keyCode}" released [event: keydown]`);
                //}
                if (type == "number") {
                    InputTypeRegex("number", e, keyCode);
                }
                else if (type == "email") {
                    InputTypeRegex("email", e, keyCode);
                }
                else if (type == "character") {
                    InputTypeRegex("character", e, keyCode);
                }
                else if (type == "alphanumber") {
                    InputTypeRegex("alphanumber", e, keyCode);
                }
                //if (!(keyCode >= "0" && keyCode <= "9" || keyCode == "Backspace" || keyCode == "Enter" || keyCode == "Tab" || keyCode == "Delete" || keyCode == "Control")) {
                //    e.preventDefault();
                //}
            }

        }
        else if (e.keyCode != "undefined" || e.keyCode != null || e.key != 229 || e.key != "Unidentified") {
            keyCode = e.keyCode || e.which;
            //console.log(keyCode);
            if ((event == "keyup") || (event == "keydown")) {
                //if (event == "keyup") {
                //    //console.log(`Key "${e.key}" released [event: keyup]`);

                //} else if (event == "keydown") {
                //    //console.log(`KeyCode "${e.keyCode}" released [event: keydown]`);
                //}
                if (type == "number") {
                    InputTypeRegex("number", e, keyCode, false);
                }
                else if (type == "email") {
                    InputTypeRegex("email", e, keyCode, false);
                }
                else if (type == "character") {
                    InputTypeRegex("character", e, keyCode, false);
                }
                else if (type == "alphanumber") {
                    InputTypeRegex("alphanumber", e, keyCode, false);
                }

                //if (!(keyCode >= 96 && keyCode <= 105 || keyCode >= 48 && keyCode <= 57 || keyCode == 8 || keyCode == 9 || keyCode == 13 || e.ctrlKey || arrowsKeyCodes.includes(keyCode)) || e.shiftKey) {
                //    e.preventDefault();
                //}
                //console.log(`Data "${e.keyCode}" released [event: input]`);
            }

        }
        //else if (e.which != "undefined" || e.which != null) {
        //    if ((event == "keyup") || (event == "keydown")) {
        //        if (event == "keyup") {
        //            //console.log(`Key "${e.key}" released [event: keyup]`);

        //        } else if (event == "keydown") {
        //            //console.log(`KeyCode "${e.keyCode}" released [event: keydown]`);
        //        }
        //        //console.log(`Data "${e.which}" released [event: input]`);
        //    }

        //}
        else if (e.data != "undefined" || e.data != null) {
            if (event == "input") {
                //console.log(e);

                keyCode = e.data;
                //console.log(keyCode);
                //var regex = /^[0-9]+$/;
                //if (!regex.test(keyCode)) {
                //    e.preventDefault();
                //}
                //if (!(keyCode >= "0" && keyCode <= "9" || keyCode == "null")) {
                //    e.target.value = e.target.value.replace(/^[a-z\s'&]*$/i, '');
                //}

                if (type == "number") {
                    InputTypeRegex("number", e, keyCode);
                }
                else if (type == "email") {
                    InputTypeRegex("email", e, keyCode);
                }
                else if (type == "character") {
                    InputTypeRegex("character", e, keyCode);
                }
                else if (type == "alphanumber") {
                    InputTypeRegex("alphanumber", e, keyCode);
                }

                //console.log(`Data "${e.data}" released [event: input]`);
            }
        }
        else {

            if (type == "number") {
                e.target.value = e.target.value.replace(/^[a-z\s'&]*$/i, '');
            }
            else if (type == "email") {
                e.target.value = e.target.value;
            }
            else if (type == "character") {
                e.target.value = e.target.value.replace(/^[0-9\s'&]*$/i, '');
            }
            else if (type == "alphanumber") {
                e.target.value = e.target.value;
            }
            //console.log(`Which "${e.which}" released [event: Which]`);
        }
    }));
}

