window.setCanonicalUrl = function (url) {
    var canonicalLink = document.querySelector("link[rel='canonical']");

    if (canonicalLink) {
        canonicalLink.href = url;
    } else {
        var newLink = document.createElement("link");
        newLink.rel = "canonical";
        newLink.href = url;
        document.head.appendChild(newLink);
    }
};


//window.setHrefLangUrl = function (url, lang) {
//    var canonicalLink = document.querySelector("link[rel='alternate']");

//    if (canonicalLink) {
//        canonicalLink.href = url;
//        newLink.hreflang = lang;
//    } else {
//        lang.forEach((item) => {
//            console.log(item);
//            var newLink = document.createElement("link");
//            newLink.rel = "alternate";
//            newLink.href = url;
//            newLink.hreflang = item;
//            document.head.appendChild(newLink);
//        })


//    }
//};


function removeHrefLangUrl() {
    var canonicalLinks = document.querySelectorAll("link[hreflang]");

    canonicalLinks.forEach(link => {
        link.remove();
    });
}


function setHrefLangUrl(url, lang, hrefLangflag = true) {

    if (hrefLangflag) {
        removeHrefLangUrl();
    }

    var newLink = document.createElement("link");
    newLink.rel = "alternate";
    newLink.href = url;
    newLink.hreflang = lang;
    document.head.appendChild(newLink);
}


