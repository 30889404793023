function mediaQueryCheck(resolution) {
    var mediaQuery = window.matchMedia("(max-width:" + resolution + "px)");
    if (mediaQuery.matches) {
        console.log("Media query matched");
        return true;
    } else {
        console.log("Media query no longer matches");
        return false;
    }
    return false;
}