function PushDataLayer(Key, Value) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        [Key]: Value,
    });
}

//function PushDataLayerList(obj) {
//    window.dataLayer = window.dataLayer || [];
//    console.log(obj);
//    obj.forEach(function (item) {
//        var DataObj = {
//            [item.key]: item.value,
//        };
//        window.dataLayer.push(DataObj);
//    });
//}

function PushDataLayerList(obj) {
    window.dataLayer = window.dataLayer || [];
    //console.log(obj);
    var DataObj = {};
    obj.forEach(function (item) {
        var x = item.key;
        var y = item.value;
     /*   DataObj.push({ [x]: y });*/

        DataObj = Object.assign({ [x]: y }, DataObj);
    });

    //console.log(DataObj);
    window.dataLayer.push(DataObj);
}