
function lightmode() {
    const storedTheme = localStorage.getItem('theme');

    //if (storedTheme == 'undefined' || storedTheme == null) {
    //    localStorage.setItem('theme', 'auto')
    //}

    const getPreferredTheme = () => {
        if (storedTheme) {
            return storedTheme;
        }
        if (storedTheme == 'undefined' || storedTheme == null) {
            localStorage.setItem('theme', 'auto');
            return "auto";
            //localStorage.setItem('theme', 'light');
            //return "light";
        }
        return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    };




    const setTheme = function (theme) {
        document.documentElement.setAttribute('data-bs-theme', 'light');

        if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            document.documentElement.setAttribute('data-bs-theme', 'dark');
        } else {
            document.documentElement.setAttribute('data-bs-theme', theme);
        }
    };

    setTheme(getPreferredTheme());

    var el = document.querySelector('.theme-icon-active');

    if (el != 'undefined' && el != null) {
        const showActiveTheme = theme => {
            const activeThemeIcon = document.querySelector('.theme-icon-active use');
            const btnToActive = document.querySelector(`[data-bs-theme-value="${theme}"]`);
            const svgOfActiveBtn = btnToActive.querySelector('.mode-switch use').getAttribute('href');

            document.querySelectorAll('[data-bs-theme-value]').forEach(element => {
                element.classList.remove('active');
            });

            btnToActive.classList.add('active');
            activeThemeIcon.setAttribute('href', svgOfActiveBtn);
        };

        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
            if (storedTheme !== 'light' || storedTheme !== 'dark') {
                setTheme(getPreferredTheme());
            }
        });

        showActiveTheme(getPreferredTheme());

        document.querySelectorAll('[data-bs-theme-value]')
            .forEach(toggle => {
                toggle.addEventListener('click', () => {
                    const theme = toggle.getAttribute('data-bs-theme-value');
                    localStorage.setItem('theme', theme);
                    setTheme(theme);
                    showActiveTheme(theme);
                });
            });

    }


}

//const mql = window.matchMedia("(prefers-color-scheme: dark)");

//function UpdateDarkModeEventListener(dotNetObject) {
 
//    console.log(mql);

//    mql.addEventListener("change", (event) => {
//        //dotNetObject.invokeMethodAsync('UpdateDarkModeStatus');
//        console.log("LightMode Updated");
//    });

//}

//UpdateDarkModeEventListener();