function submitForm(formId) {
    document.getElementById(formId).submit();
}

window.isIOSDevice = function () {
    var userAgent = navigator.userAgent;
    return /iPhone|iPad/i.test(userAgent);
};



window.isMobileDevice = function () {
    var userAgent = navigator.userAgent;
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(userAgent);
};





