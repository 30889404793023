function addOnPasteEventListener(elem, dotNetObject) {
    //console.log("in On Paste JS Function");
    //console.log(elem);

    elem.onpaste = (e) => {
        var text = e.clipboardData.getData('text');
        var data = e.originalEvent.clipboardData.getData('Text');
        data = data.replace(new RegExp(/^[A-Za-z0-9]+$/, ''), ',');
        dotNetObject.invokeMethodAsync('HandlePaste', data);
    };
}

//function addOnPasteEventListener(elem, dotNetObject) {
//    //console.log("in On Paste JS Function");
//    //console.log(elem);
//    var s = document.getElementById(elem);
//    elem.onpaste = (e) => {
//        var text = e.clipboardData.getData('text');
//        //var data = e.originalEvent.clipboardData.getData('Text');
//        //data = data.replace(new RegExp(/^[A-Za-z0-9]+$/, ''), ',');
//        //s.value = data;
//        dotNetObject.invokeMethodAsync('HandlePaste', text);
//    }
//}

//window.customFunctions = {
//    onPasteEvent: function (event) {
//        var pasted = event.clipboardData.getData('Text');

//        DotNet.invokeMethod('HerePasteYourAsseblyName', 'OnTextAreaPaste', pasted)
//    }
//}



//window.addEventListener("paste", (event) => {
//    console.log("paste action initiated");
//});


//function addOnPasteEventListener(elem, dotNetObject) {
//    console.log("in On Paste JS Function");
//    console.log(elem);

//    elem.onpaste = (e) => {
//        var text = e.clipboardData.getData('text');
//        dotNetObject.invokeMethodAsync('HandlePaste', text);
//    }
//}


//var phone = document.getElementById('numberonly'),
//	cleanPhoneNumber;

//cleanPhoneNumber = function (e) {
//	e.preventDefault();
//	var pastedText = '';
//	if (window.clipboardData && window.clipboardData.getData) { // IE
//		pastedText = window.clipboardData.getData('Text');
//	} else if (e.clipboardData && e.clipboardData.getData) {
//		pastedText = e.clipboardData.getData('text/plain');
//	}
//	this.value = pastedText.replace(/\D/g, '');
//};

//phone.onpaste = cleanPhoneNumber;