
function encrypted(value,privateKey) {
    var encrypted = CryptoJS.AES.encrypt(value, privateKey).toString();
    return encrypted;
}

function decrypted(encrypted, privateKey) {
    debugger;
    var decrypted = CryptoJS.AES.decrypt(encrypted, privateKey);
    var decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
    console.log(decryptedData);
    return decryptedData;
}




//function crypt(salt, text) {
//    var textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
//    var byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
//    var applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

//    return text
//        .split("")
//        .map(textToChars)
//        .map(applySaltToChar)
//        .map(byteHex)
//        .join("");
//};

//function decrypt (salt, encoded){
//    var textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
//    var applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
//    return encoded
//        .match(/.{1,2}/g)
//        .map((hex) => parseInt(hex, 16))
//        .map(applySaltToChar)
//        .map((charCode) => String.fromCharCode(charCode))
//        .join("");
//};

//// encrypting
//const encrypted_text = crypt("123456asjhdbajshdbahjsbdhjasbdjhsadb", "Hello"); // -> 426f666665

//console.log(encrypted_text);

//// decrypting
//const decrypted_string = decrypt("salt", "426f666665"); // -> Hello

//console.log(decrypted_string);