var firstRender =false;
var hideOfferProgress = true;
function progressbar(qoutationRequestLenght, qoutationResponseLenght, lang) {
    if (!firstRender) {
        
        var OfferMain = document.getElementById("offer-main-1");
        if (OfferMain != null || OfferMain != undefined) {
            var skillPer = document.getElementById("offer-per");
            //var qoutationResponse = (100 / (qoutationRequestLenght - qoutationResponseLenght));
            var qoutationResponse = (qoutationResponseLenght * 100 / (qoutationRequestLenght));
            skillPer.style.width = Math.floor(qoutationResponse) + "%";
            var per = document.getElementById("per");
            // skillPer.setAttribute("#per", Math.floor(qoutationResponse) + "%");
         
            if (qoutationResponse.toString() != "Infinity") {
                per.innerText = Math.floor(qoutationResponse) + "%";
                if (Math.floor(qoutationResponse) > 5) {
                    per.classList.add("animated");
                    per.classList.add("fade-in-scale");
                    per.classList.remove("opacity-0");
                }

                if (Math.floor(qoutationResponse) > 90) {
                    if (!lang) {
                        per.style.right = "10px";
                    } else {
                        per.style.left = "10px";
                    }
                } else {
                    if (!lang) {
                        per.style.right = "-20px";
                    } else {
                        per.style.left = "-20px";
                    }
                }
                if (Math.floor(qoutationResponse) === 100 || qoutationResponse.toString() === "Infinity") {
                    OfferMain.classList.add("animated");
                    OfferMain.classList.add("fade-out");
                    hideOfferProgress = false;

                    setTimeout(() => {
                        OfferMain.classList.add("opacity-0");
                        firstRender = true;


                    }, 1500);
                }
            } else {
                per.innerText = "100%";
            }
        }

    } 
    return hideOfferProgress;
}