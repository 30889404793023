
function UpdateBrowserWidthEventListener(dotNetObject) {

    window.addEventListener("resize", (event) => {
        dotNetObject.invokeMethodAsync('UpdateWidth');

    });

}


window.getDimensions = function () {
    return {
        width: window.innerWidth,
        height: window.innerHeight
    };
};
