function MaxLength(Selector, MaxLenght) {
    var Ele = document.querySelectorAll(Selector);
    Ele.forEach(function (item) {
        var Input = item.getElementsByTagName("input");
        //console.log(Input);
        Input.addEventListener('keyup', () => {
            if (item.value.length > MaxLenght) {
                item.value = item.value.slice(0, MaxLenght);
            }
        });
    });
}