
function removeClass(Element, className) {
    var selectors = document.getElementById(Element);
    if (typeof selectors === "undefined" && selectors.value == '' || selectors === null) {
         selectors = document.querySelectorAll(Element);
        if (typeof selectors === "undefined" && selectors.value == '' || selectors === null) {


        }
        else {
            selectors.forEach((item) => {
                item.classList.remove(className);

            });
        }

    } else {
        selectors.classList.remove(className);
    }
}
function addClass(Element, className) {
    var selectors = document.getElementById(Element);
    if (typeof selectors === "undefined" && selectors.value == '' || selectors === null) {
         selectors = document.querySelectorAll(Element);
        if (typeof selectors === "undefined" && selectors.value == '' || selectors === null) {
            

        } else {
            selectors.forEach((item) => {
                item.classList.add(className);

            });
        }

    } else {
        selectors.classList.add(className);
    }
}

function toggleClass(Element, className) {
    var selectors = document.getElementById(Element);
    if (typeof selectors === "undefined" && selectors.value == '' || selectors === null) {
         selectors = document.querySelectorAll(Element);
        if (typeof selectors === "undefined" && selectors.value == '' || selectors === null) {


        } else {
            selectors.forEach((item) => {
                item.classList.toggle(className);

            });
        }

    } else {
        selectors.classList.toggle(className);
    }

}

