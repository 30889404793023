

window.clipboardCopy = {
    copyText: function (text) {
        navigator.clipboard.writeText(text).then(function () {
            //alert("Copied to clipboard!");
        })
            .catch(function (error) {
                alert(error);
            });
    }
}

function triggerClick(elt) {
    //console.log("now in triggerClick");
    elt.click();
}

function onSubmit(form) {
    var x = document.getElementById(form);
    document.forms[form].submit();
    var y = document.getElementById("submit");
}

function focusElement(element) {

    var ElementTwofocus = document.getElementById(element);
    if (ElementTwofocus != null)
        ElementTwofocus.focus();
}




function initPreloader() {


    //console.log("page is fully loaded");

    //var fadeTarget = document.getElementById("status");
    //var fadeEffect = setInterval(function () {
    //    if (!fadeTarget.style.opacity) {
    //        fadeTarget.style.opacity = 1;
    //    }
    //    if (fadeTarget.style.opacity > 0) {
    //        fadeTarget.style.opacity -= 0.1;
    //    } else {
    //        clearInterval(fadeEffect);
    //    }
    //}, 1000);

    var fadeTarget = document.getElementById("preloader");


    var fadeEffect = setInterval(function () {
        //console.log("fade")
        if (!fadeTarget.style.opacity) {
            fadeTarget.style.opacity = 1;
        }
        if (fadeTarget.style.opacity > 0) {
            fadeTarget.style.opacity -= 0.1;
        } else {
            clearInterval(fadeEffect);
        }
    }, 200);


}

function init() {
    //initMetisMenu();
    //initLeftMenuCollapse();
    //initActiveMenu();
    //initMenuItem();
    //initFullScreen();
    //initRightSidebar();
    //initDropdownMenu();
    //initComponents();
    //initSettings();
    //console.log("page is init");

    initPreloader();
    //Waves.init();
}
